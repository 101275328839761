import React, { useState } from "react";
import styled from "styled-components";

import { ChevronIcon } from "../../../common/svg/chevron";

interface SectionRow {
  title: string;
  value: string;
}

interface CollapsableRowsSectionProps {
  rows: SectionRow[];
  accountIsSavings: boolean;
}

export const CollapsableRowsSection = (props: CollapsableRowsSectionProps) => {
  const { rows, accountIsSavings = false } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <StyledSectionContainer>
      <StyledSectionHeader onClick={() => setIsOpened(!isOpened)}>
        <StyledHeaderLabel accountIsSavings={accountIsSavings}>{rows[0].title}</StyledHeaderLabel>
        <StyledHeaderSeparator>—</StyledHeaderSeparator>
        <StyledHeaderLabel accountIsSavings={accountIsSavings}>{rows[0].value}</StyledHeaderLabel>
        <ArrowDown width={16} height={16} fill={"#ADADAD"} reversed={isOpened} />
      </StyledSectionHeader>
      {isOpened && (
        <StyledRowContainer>
          {rows.slice(1, rows.length).map((r) => (
            <StyledRow key={r.title}>
              <StyledRowText accountIsSavings={accountIsSavings}>{r.title}</StyledRowText>
              <StyledRowText accountIsSavings={accountIsSavings}>{r.value}</StyledRowText>
            </StyledRow>
          ))}
        </StyledRowContainer>
      )}
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled.div`
  margin-left: 30px;
  margin-right: 30px;
`;

const StyledSectionHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px 1fr auto;
  align-items: center;
  gap: 8px;
  margin-bottom: 7px;
  margin-top: 8px;
`;

const StyledHeaderSeparator = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  color: #d6d6d6;
  text-align: center;
`;

const StyledRowText = styled.span<{ accountIsSavings: boolean }>`
  font-weight: 500;
  ${(accountIsSavings) => (accountIsSavings ? "#212121" : "#666666")};
  margin-bottom: 7px;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
`;

const StyledRowContainer = styled.div`
  border-left-color: #d6d6d6;
  border-left-width: 1px;
  border-left-style: solid;
  padding-left: 8px;
  padding-top: 6px;
  margin-left: 6px;
  margin-right: 34px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ArrowDown = styled(ChevronIcon)<{ reversed: boolean }>`
  transform: ${(props) => (props.reversed ? "rotate(-90deg)" : "rotate(90deg)")};
`;

const StyledHeaderLabel = styled.span<{ accountIsSavings: boolean }>`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(accountIsSavings) => (accountIsSavings ? "#ADADAD" : "#212121")};
  font-weight: 500;
  font-size: 15px;
  font-style: normal;
  line-height: normal;

  &:nth-of-type(3) {
    text-align: right;
  }
`;
