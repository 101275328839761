import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { accountBankDetailsService } from "../../../../../shared/core/service/services";
import type { Account } from "../../../../../shared/domains/account/account";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import { Spinner } from "../../../common/spinner";
import { DownloadIcon } from "../../../common/svg/download-icon";
import { shadows, theme } from "../../../styles/theme";

interface BankAccountDetailsProps {
  account: Account;
}
export const DownloadBankAccountDetails = (props: BankAccountDetailsProps) => {
  const { account } = props;
  const { formatMessage } = useIntl();
  const [isDownloading, setIsDownloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await accountBankDetailsService.downloadAccountBankDetails(account.id);
    } catch (e) {
      console.log(e.response.data.error.message);
      setErrorMessage(e.response.data.error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(null);
    }
  }, [errorMessage]);

  return (
    <>
      <BankDetailsContainer>
        <DownloadBankDetails>
          {formatMessage("accountDetails.downloadBankDetails")}
          {isDownloading ? <Spinner /> : null}
          <GreyRoundedSquareButton size={34} onClick={handleDownload}>
            <DownloadIcon />
          </GreyRoundedSquareButton>
        </DownloadBankDetails>
      </BankDetailsContainer>
    </>
  );
};

const DownloadBankDetails = styled.div`
  ${shadows.medium};
  ${theme.boldText};
  width: 100%;
  font-size: 0.9375rem;
  margin: 10px 0;
  padding: 15px;
  border-radius: 13px;
  border: 0 none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GreyRoundedSquareButton = styled(RoundedSquareButton)`
  background-color: #f4f4f4;
`;

const BankDetailsContainer = styled.div`
  padding: 0 30px 10px 30px;
`;
