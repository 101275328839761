import React, { useCallback } from "react";

import type { FormAutocompleteInput } from "../../../../shared/core/data-forms/form-input-types";
import { AutocompleteInput } from "../forms/autocomplete-input";
import { DisableValueText } from "./disable-value-text";

export interface AutocompleteFieldProps {
  input: FormAutocompleteInput;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({ input, disabled = false, onChange }) => {
  const handleOnChange = useCallback(
    (value?: string) => {
      if (onChange) {
        onChange(value ?? "");
      }
    },
    [onChange],
  );

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <AutocompleteInput inputId={input.id} value={input.value ?? ""} onChange={handleOnChange} />
  );
};
